import { ANALYTICS_CONFIG_ELEMENT_ID } from './constants';
import { AnalyticManager } from './classes/manager';
import { FacebookBusinessExtensionInitializer } from './services/facebook_business_extension/facebook_business_extension_initializer';
import Features from '@core/tools/features';
import { GoogleAnalyticsV4Initializer } from './services/google_analytics_v4/initializer';
import { TAnalyticsOptions } from './types';
import customerPrivacy from '@frontstoreRwd/modules/customer_privacy/index';
import { getJSONFromScriptTag } from '@core/dom_utilities/get';
import { CAMPAIGN_GOOGLE_ANALYTICS_V4 } from '@frontstoreRwd/modules/analytics/services/google_analytics_v4/constants';

export class AnalyticsInitializer {
    private _analyticsOptions: TAnalyticsOptions | undefined;
    private _analyticsManager: AnalyticManager;

    constructor() {
        this._analyticsOptions = getJSONFromScriptTag(ANALYTICS_CONFIG_ELEMENT_ID) as TAnalyticsOptions;
        this._analyticsManager = new AnalyticManager();
    }

    public async init(): Promise<void> {
        customerPrivacy.onAnalyticsConsentGranted(() => {
            if (!this._analyticsOptions) {
                return;
            }

            if (
                Features.isEnabled('google_analytics_v4') ||
                Features.isEnabled('partner_google_analytics_v4') ||
                Features.isEnabled('google_analytics_v4_campaign')
            ) {
                new GoogleAnalyticsV4Initializer(this._analyticsManager, [
                    this._analyticsOptions.clientGoogleAnalyticsV4,
                    {
                        ...this._analyticsOptions.campaignGoogleAnalyticsV4,
                        ...CAMPAIGN_GOOGLE_ANALYTICS_V4
                    }
                ]);
            }

            if (Features.isEnabled('facebook_business_extension')) {
                new FacebookBusinessExtensionInitializer(this._analyticsManager, this._analyticsOptions.facebookBusinessExtension).init();
            }
        });
    }
}
