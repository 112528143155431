import { TGoogleAnalyticsV4GTMEvent, TGoogleAnalyticsV4GTMOptions } from './types';

import { AnalyticService } from '@frontstoreRwd/modules/analytics/classes/service';
import { TServiceName } from '../../types';
import eventsHandlersMapper from './events';
import { GoogleAnalyticsV4Map } from '@frontstoreRwd/modules/analytics/services/google_analytics_v4/mapper';
import { TGoogleAnalyticsV4GtagOptions } from '@frontstoreRwd/modules/analytics/services/google_analytics_v4/gtag/types';

export class GoogleAnalyticV4GTMService extends AnalyticService<GoogleAnalyticV4GTMService> {
    private _options: TGoogleAnalyticsV4GTMOptions;
    private _layer: unknown[] = [];
    private _mapper = GoogleAnalyticsV4Map;

    protected _events: TGoogleAnalyticsV4GTMEvent = eventsHandlersMapper;

    constructor(name: TServiceName, options: TGoogleAnalyticsV4GTMOptions) {
        super(name);
        this._options = options;
    }

    public async setup(): Promise<void> {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this._layer = window[this._options.dataLayerName] || [];
        this._isSetuped = true;
    }

    public getLayer(): unknown[] {
        return this._layer;
    }

    public getMapper(): typeof GoogleAnalyticsV4Map {
        return this._mapper;
    }

    public getRemarketingOptions(): TGoogleAnalyticsV4GtagOptions['googleRemarketing'] {
        return this._options.googleRemarketing;
    }

    public getTrackingId(): string[] {
        return this._layer.filter((obj: any) => obj && obj[0] && obj[0] === 'config' && obj[1].includes('G-')).map((obj: any) => obj[1]);
    }
}
