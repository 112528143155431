//@ts-nocheck

import { IApiBasketInformation, IApiBasketProduct } from '@frontstoreApi/basket/types';
import { store } from '@frontstoreRwd/store';
import { areProductsInBasket, doesBasketExist } from '@frontstoreRwd/modules/basket_manage/basket_utils';
import { createElementFromHTMLString } from '@core/dom_utilities/create';
import { liTemplate } from '@frontstoreRwd/modules/basket_manage/templates';

const selectors = {
    basket: '.logo-bar .basket',
    basketContain: '.logo-bar .basket-contain',
    basketSummary: '.logo-bar .basket-summery'
};

export default {
    $sum: null as HTMLElement | null,
    $count: null as HTMLElement | null,
    $countLabel: null as HTMLElement | null,
    $productList: null as HTMLElement | null,
    $basketTotal: null as HTMLElement | null,
    $basketTotalAfterDiscount: null as HTMLElement | null,
    $mobileBasketTotal: null as HTMLElement | null,

    basketHeaderPreviewInitialize(): void {
        this.$sum = document.querySelector(`${selectors.basket} b.sum`);
        this.$count = document.querySelector(`${selectors.basket} b.count`);
        this.$countLabel = document.querySelector(`${selectors.basket} .countlabel b`);
        this.$productList = document.querySelector(`${selectors.basketContain} .basket-product-list`);
        this.$basketTotal = document.querySelector(`${selectors.basketContain} .price-total .price-products`);
        this.$basketTotalAfterDiscount = document.querySelector(`${selectors.basketContain} .price-total-discount .price-total`);
        this.$mobileBasketTotal = document.querySelector('[data-basket-count]') || document.querySelector('.menu-mobile .icon-custom-cart');
    },

    createHighlightElements(): HTMLElement[] {
        const $sum = document.createElement('b');
        $sum.classList.add('sum');

        const $count = document.createElement('b');
        $count.classList.add('count');

        return [$sum, $count];
    },

    fillHighlightWithData(basketInfo: IApiBasketInformation): void {
        if (this.$sum) {
            this.$sum.textContent = basketInfo.basket.sum_no_shipping;
        }

        if (this.$count) {
            this.$count.textContent = ' (';

            const endBracket = document.createTextNode(')');
            const span = document.createElement('span');
            span.textContent = `${basketInfo.basket.count}`;

            this.$count.append(span, endBracket);
        }

        if (this.$mobileBasketTotal) {
            this.$mobileBasketTotal.setAttribute('data-basket-count', `${basketInfo.basket.count}`);
        }
    },

    clearHighlight() {
        if (this.$countLabel) {
            // @ts-ignore
            this.$countLabel.textContent = `(${window.Shop.lang.common.empty})`;
        }

        if (this.$mobileBasketTotal) {
            this.$mobileBasketTotal.setAttribute('data-basket-count', '0');
        }
    },

    updateHighlight() {
        if (areProductsInBasket()) {
            if (!this.$sum && !this.$count) {
                const [$sum, $count] = this.createHighlightElements();

                this.$sum = $sum;
                this.$count = $count;

                if (this.$countLabel) {
                    this.$countLabel.textContent = '';
                    this.$countLabel.appendChild(this.$sum);
                    this.$countLabel.appendChild(this.$count);
                }
            }

            this.fillHighlightWithData(store.basketInfo!);
        } else {
            this.clearHighlight();
        }
    },

    createBasketPreviewProductList(products: IApiBasketProduct[]) {
        const $list = document.createDocumentFragment();

        products.forEach((product) => {
            const $li: ChildNode = createElementFromHTMLString(
                liTemplate({
                    productId: String(product.id),
                    categoryName: product.category.translation.name,
                    producerName: product.producer?.name,
                    imgFileName: product.image_filename ? String(product.image_filename) : '__overlay.jpg',
                    productName: product.name,
                    productVariantName: product.variant,
                    quantity: String(product.quantity),
                    price: product.price,
                    removeUrl: product.remove_url,
                    productUrl: product.url,
                    // @ts-ignore
                    removePhrase: window.Shop.lang.common.remove
                })
            );

            $list.appendChild($li);
        });

        return $list;
    },

    fillListWithData(basketInfo: IApiBasketInformation) {
        const productsWithoutBundleChildren = basketInfo.products.filter((product: IApiBasketProduct) => product.type !== 2);

        this.clearList();
        this.$productList?.appendChild(this.createBasketPreviewProductList(productsWithoutBundleChildren));
    },

    clearList() {
        if (this.$productList) {
            this.$productList.textContent = '';
        }
    },

    updateList() {
        if (areProductsInBasket()) {
            this.fillListWithData(store.basketInfo!);
        } else {
            this.clearList();
        }
    },

    createSummaryDiscountElement(sum: string) {
        const $cartDiscountSpan = document.createElement('span');
        const $cartDiscountText = document.createElement('span');
        const $cartDiscountAmount = document.createElement('strong');

        $cartDiscountSpan.classList.add('price-total-discount');
        //@ts-ignore
        $cartDiscountText.textContent = `${Shop.lang.common.basket_discount}: `;
        $cartDiscountAmount.classList.add('price-total');
        $cartDiscountAmount.textContent = sum;

        $cartDiscountSpan.appendChild($cartDiscountText);
        $cartDiscountSpan.appendChild($cartDiscountAmount);

        return $cartDiscountSpan;
    },

    fillSummaryWithData(basketInfo: IApiBasketInformation) {
        if (this.$basketTotal) {
            this.$basketTotal.textContent = basketInfo.basket.sum_products;
        }

        if (this.$basketTotalAfterDiscount) {
            this.$basketTotalAfterDiscount.textContent = basketInfo.basket.sum_no_shipping;
        } else if (
            basketInfo.basket.sum_products_float !== basketInfo.basket.sum_no_shipping_float &&
            !document.querySelector('.price-total-discount')
        ) {
            const $basketPrice = document.querySelector('.basket-price');
            $basketPrice?.classList.add('basket-price-discount');
            $basketPrice?.appendChild(this.createSummaryDiscountElement(basketInfo.basket.sum_no_shipping));
        }
    },

    clearSummary() {
        if (this.$basketTotal) {
            this.$basketTotal.textContent = '';
        }

        if (this.$basketTotalAfterDiscount) {
            this.$basketTotalAfterDiscount.textContent = '';
        }
    },

    updateSummary() {
        if (areProductsInBasket()) {
            this.fillSummaryWithData(store.basketInfo!);
        } else {
            this.clearSummary();
        }
    },

    updateHeaderBasketView(): boolean {
        if (doesBasketExist()) {
            this.updateHighlight();
            this.updateList();
            this.updateSummary();

            return true;
        }

        return false;
    }
};
