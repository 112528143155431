import {
    ADD_PAYMENT_INFO,
    ADD_SHIPPING_INFO,
    ADD_TO_CART,
    BEGIN_CHECKOUT,
    PURCHASE,
    REMOVE_FROM_CART,
    SELECT_ITEM,
    VIEW_CART,
    VIEW_ITEM,
    VIEW_ITEM_LIST
} from '../event_names';
import {
    BEGAN_CHECKOUT,
    CART_DISPLAYED,
    CHECKOUT_OPTIONS_SUBMITED,
    PRODUCTS_DISPLAYED,
    PRODUCT_ADDED_TO_CART,
    PRODUCT_CLICKED,
    PRODUCT_DISPLAYED,
    PRODUCT_REMOVED_FROM_CART,
    PURCHASED
} from '@frontstoreRwd/events/events_names';

import { AnalyticProduct } from '@frontstoreRwd/modules/analytics/classes/product';
import { GoogleAnalyticV4GtagService } from '.';
import { GoogleAnalyticsV4Map } from '../mapper';
import { TGoogleAnalyticsV4GtagEvent } from './types';
import { TPurchaseParams } from '../types';

const eventsHandlersMapper: TGoogleAnalyticsV4GtagEvent = {
    [PRODUCT_CLICKED]: (service: GoogleAnalyticV4GtagService, analyticProduct: unknown): void => {
        if (analyticProduct instanceof AnalyticProduct) {
            const gtag = service.getGtag();
            const analyticsV4Product = GoogleAnalyticsV4Map.toV4Product(analyticProduct);

            gtag('event', SELECT_ITEM, {
                items: [analyticsV4Product],
                item_list_name: analyticsV4Product.item_list_name,
                item_list_id: analyticsV4Product.item_list_name
            });
        }
    },

    [PRODUCT_ADDED_TO_CART]: (service: GoogleAnalyticV4GtagService, analyticProduct: unknown): void => {
        if (analyticProduct instanceof AnalyticProduct) {
            const gtag = service.getGtag();
            const analyticsV4Product = GoogleAnalyticsV4Map.toV4Product(analyticProduct);

            gtag('event', ADD_TO_CART, {
                items: [analyticsV4Product],
                currency: analyticsV4Product.currency,
                value: analyticsV4Product.price
            });
        }
    },

    [PRODUCT_REMOVED_FROM_CART]: (service: GoogleAnalyticV4GtagService, analyticProduct: unknown): void => {
        if (analyticProduct instanceof AnalyticProduct) {
            const gtag = service.getGtag();
            const analyticsV4Product = GoogleAnalyticsV4Map.toV4Product(analyticProduct);

            gtag('event', REMOVE_FROM_CART, {
                items: [analyticsV4Product],
                currency: analyticsV4Product.currency,
                value: analyticsV4Product.price
            });
        }
    },

    [PRODUCT_DISPLAYED]: (service: GoogleAnalyticV4GtagService, analyticProduct: unknown): void => {
        if (analyticProduct instanceof AnalyticProduct) {
            const gtag = service.getGtag();
            const analyticsV4Product = GoogleAnalyticsV4Map.toV4Product(analyticProduct);

            gtag('event', VIEW_ITEM, {
                items: [analyticsV4Product],
                currency: analyticsV4Product.currency,
                value: analyticsV4Product.price
            });
        }
    },

    [PRODUCTS_DISPLAYED]: (service: GoogleAnalyticV4GtagService, params: unknown): void => {
        if (typeof params === 'object') {
            const gtag = service.getGtag();
            const analyticProductsList = params as {
                products: AnalyticProduct[];
                currency: string;
            };
            const analyticsV4Products = analyticProductsList.products.map((product: AnalyticProduct) =>
                GoogleAnalyticsV4Map.toV4Product(product)
            );

            gtag('event', VIEW_ITEM_LIST, {
                currency: analyticProductsList.currency,
                items: analyticsV4Products
            });
        }
    },

    [CART_DISPLAYED]: (service: GoogleAnalyticV4GtagService, params: unknown): void => {
        if (typeof params === 'object') {
            const gtag = service.getGtag();
            const analyticProductsList = params as {
                products: AnalyticProduct[];
                currency: string;
                coupon: string | undefined;
            };
            const analyticsV4Products = analyticProductsList.products.map((product: AnalyticProduct) =>
                GoogleAnalyticsV4Map.toV4Product(product)
            );

            gtag('event', VIEW_CART, {
                currency: analyticProductsList.currency,
                items: analyticsV4Products,
                coupon: analyticProductsList.coupon
            });
        }
    },

    [BEGAN_CHECKOUT]: (service: GoogleAnalyticV4GtagService): void => {
        const gtag = service.getGtag();
        gtag('event', BEGIN_CHECKOUT);
    },

    [CHECKOUT_OPTIONS_SUBMITED]: (service: GoogleAnalyticV4GtagService, params: unknown): void => {
        if (typeof params === 'object') {
            const gtag = service.getGtag();
            const checkoutParams = params as {
                shippingName: string;
                paymentName: string;
                currency: string;
            };

            gtag('event', ADD_PAYMENT_INFO, {
                currency: checkoutParams.currency,
                payment_type: checkoutParams.paymentName
            });

            gtag('event', ADD_SHIPPING_INFO, {
                currency: checkoutParams.currency,
                shipping_type: checkoutParams.shippingName
            });
        }
    },

    [PURCHASED]: (service: GoogleAnalyticV4GtagService, params: unknown): void => {
        if (typeof params === 'object') {
            const gtag = service.getGtag();
            const purchaseParams = params as TPurchaseParams;
            const analyticsV4Order = GoogleAnalyticsV4Map.toV4Order(purchaseParams, service.getRemarketingOptions(), service.getTrackingId());

            gtag('event', PURCHASE, analyticsV4Order);
            gtag('set', 'user_data', {
                email: sessionStorage.getItem('basket_email')
            });
        }
    }
};

export default eventsHandlersMapper;
