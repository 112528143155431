export const CSS_HINT_CLASS = 'hint';
export const CSS_HINT_CONTENT_CLASS = 'hint__content';

// for backstore
export const CSS_HIDE_CLASS = 'hide';
export const CSS_LABEL_REQUIRED_CLASS = 'label_required';

export const CSS_CONTROL_CLASS = 'control';
export const CSS_CONTROL_DISABLED_CLASS = 'control_disabled';

export const CSS_CHECKBOX_CLASS = 'checkbox';
export const CSS_CHECKBOX_DISABLED_CLASS = 'checkbox_disabled';

export const CSS_ICON_ROTATE_90_CLASS = 'icon_rotate-90';
export const CSS_ICON_ROTATE_NEG_90_CLASS = 'icon_rotate-neg-90';

// for frontstore
export const CSS_NONE_CLASS = 'none';
