import {
    PRODUCTS_DISPLAYED,
    PRODUCT_ADDED_TO_CART,
    PRODUCT_CLICKED,
    PRODUCT_DISPLAYED,
    PRODUCT_REMOVED_FROM_CART
} from '@frontstoreRwd/events/events_names';

import { $emit } from '@core/tools/event_bus';
import { AnalyticProduct } from '@frontstoreRwd/modules/analytics/classes/product';
import Features from '@core/tools/features';
import customerPrivacy from '@frontstoreRwd/modules/customer_privacy/index';

(function (undefined) {
    Shop.EnhancedEcommerce.include({
        options: {
            currency: null,
            licenseId: null,
            fmc: false,
            userType: null,
            basketType: null,
            partnerData: null,

            type: null,
            themeType: 'rwd'
        },

        initialize: function (options) {
            var self;
            var $html;
            var $remove;
            var $add;
            var partnerData;
            var flag;
            var filteredAddArr;

            $html = $('html');
            this.constructor.setOptions(options);
            this.options.currency = Shop.values.currency;
            this.options.fmc = ($html.data('fmc') || false) + '';
            this.options.userType = this.parent.getVisitorId() ? 'customer' : 'guest';
            this.options.basketType = Shop.values.ShippingHidden ? '5 steps' : '4 steps';

            if (Shop.values.partnerData) {
                this.options.partnerData = Shop.values.partnerData + '';
                partnerData = this.options.partnerData.substring(10);
                this.options.licenseId = partnerData.substring(1);
                Shop.values.licenseId = partnerData.substring(1);
                flag = partnerData.charAt(0);

                if (flag === '3') {
                    this.options.type = 'trial';
                } else if (flag === '1') {
                    this.options.type = 'saas';
                } else {
                    this.options.type = 'lifetime';
                }
            }

            customerPrivacy.onAnalyticsConsentGranted(() => {
                if (window.ga && Shop.values.clientEE) {
                    ga('client.require', 'ec');
                    ga('client.set', '&cu', this.options.currency);
                }
            });

            this.displayProducts();

            self = this;
            this.parent.addEvent('cart:add', function (ev, evForm, options, $form) {
                if (!options.preventSubmit) {
                    self.addedToCart($form);
                }
            });

            this.parent.addEvent('cart:add:ajax', function (ev, $form) {
                self.addedToCart($form);
            });

            $remove = $('.remove-product a, .prodremove');

            if ($remove.length > 0) {
                $remove.on('click', function (ev) {
                    ev.preventDefault();

                    self.removedFromCart($(this).parents('li, tr'));

                    if (ev.which === 2 || (ev.which === 1 && ev.ctrlKey === true)) {
                        window.open($(this).attr('href'), '_blank');
                    } else {
                        window.location = $(this).attr('href');
                    }
                });
            }

            $add = $('.product a[href]:not(.js__gallery-anchor-image), .productlist a[href]:not(.js__gallery-anchor-image)');

            if ($add.length > 0) {
                filteredAddArr = Array.prototype.filter.call($add, function (el) {
                    return !$(el).parents('.js__prodcut-short-desc').length;
                });

                $(filteredAddArr).on('click', function (ev) {
                    ev.preventDefault();

                    self.productClicked($(this).parents('.product'), $(this).parent('li'));

                    if (ev.which === 2 || (ev.which === 1 && ev.ctrlKey === true)) {
                        window.open($(this).attr('href'), '_blank');
                    } else {
                        window.location = $(this).attr('href');
                    }
                });
            }

            if ($('#box_productfull').length === 1) {
                this.displayProductDetails($('#box_productfull'));
            }

            customerPrivacy.onAnalyticsConsentGranted(() => {
                if (Shop.values.clientEE && window.ga) {
                    ga('client.send', 'pageview');
                }
            });

            if (Shop.values.partnerEE) {
                var partnerEEData = {
                    shop_id: this.options.licenseId,
                    license_type: this.options.type,
                    theme_type: this.options.themeType,
                    fmc: this.options.fmc,
                    user_type: this.options.userType,
                    basket_type: this.options.basketType
                };

                if (this.getMainlistName('mainproducts') === 'search') {
                    partnerEEData.search_results = $('.category-name').text().trim().split(' ').pop() || 0;
                }

                customerPrivacy.onPlatformConsentGranted(() => {
                    shopLayer.push(partnerEEData);
                });
            }

            Shop.EnhancedEcommerce.initialized = true;
        },

        getImpression(el) {
            const $el = $(el);
            const container = $el.parents('.box');

            if (container.length > 0) {
                return {
                    id: $el.data('productId'),
                    name: $el.find('.productname:not([itemprop])').text().trim(),
                    price: $el
                        .find('.price em:first')
                        .text()
                        .replace(/([^\d,\.])+/g, ''),
                    position: $el.index(),
                    list: container.attr('id') ? this.getMainlistName(container.attr('id').split('_')[1]) : 'undefined'
                };
            }

            return null;
        },

        displayProducts: function () {
            var self;
            var products;
            var clientProducts;
            var $products;

            self = this;
            products = [];
            clientProducts = [];

            $products = $('.product');

            const $productsFromMainList = $('#box_mainproducts .product');
            const $productsFromBoxes = $('.product').not('#box_mainproducts .product');

            const $mainListProductsCount = $productsFromMainList.length;
            const $productsFromBoxesCount = $productsFromBoxes.length;
            const impressionsLimit = 50;
            const boxLimit = 5;

            if ($mainListProductsCount > 0) {
                $productsFromMainList.each(function () {
                    if (products.length < impressionsLimit) {
                        const impression = self.getImpression(this);

                        if (impression) {
                            products.push({
                                ...impression,
                                id: self.options.licenseId + '_' + impression.id
                            });
                            clientProducts.push(impression);
                        }
                    }
                });
            }

            if ($productsFromBoxesCount > 0 && products.length < impressionsLimit) {
                const boxes = {};

                $productsFromBoxes.each(function () {
                    const $box = $(this).parents('.box');

                    if ($box.length > 0) {
                        const containerId = $box.attr('id');

                        if (!boxes[containerId]) {
                            boxes[containerId] = [];
                        }

                        if (boxes[containerId].length < boxLimit) {
                            const impression = self.getImpression(this);
                            if (impression) {
                                boxes[containerId].push(impression);
                            }
                        }
                    }
                });

                for (const boxName in boxes) {
                    if (boxes.hasOwnProperty(boxName)) {
                        const impressions = boxes[boxName];

                        impressions.forEach((impression) => {
                            if (products.length < impressionsLimit) {
                                products.push({
                                    ...impression,
                                    id: self.options.licenseId + '_' + impression.id
                                });
                                clientProducts.push(impression);
                            }
                        });
                    }
                }
            }

            if (products.length > 0) {
                customerPrivacy.onPlatformConsentGranted(() => {
                    if (Shop.values.partnerEE) {
                        shopLayer.push({
                            ecommerce: {
                                currencyCode: this.options.currency,
                                impressions: products
                            }
                        });
                    }
                });

                customerPrivacy.onAnalyticsConsentGranted(() => {
                    if (Shop.values.clientEE) {
                        if (window.ga) {
                            clientProducts.forEach(function (product) {
                                ga('client.ec:addImpression', product);
                            });
                        }
                    }
                });

                if (
                    Features.isEnabled('partner_google_analytics_v4') ||
                    Features.isEnabled('google_analytics_v4') ||
                    Features.isEnabled('facebook_business_extension')
                ) {
                    const displayedProducts = clientProducts.map((product) => new AnalyticProduct(product));
                    const eventName = PRODUCTS_DISPLAYED;
                    const eventOptions = {
                        products: displayedProducts,
                        currency: this.options.currency
                    };

                    $emit(eventName, eventOptions);
                }
            }

            return products;
        },

        displayProductDetails: function ($container) {
            var product;
            var clientProduct;

            if ($container.length > 0) {
                product = {
                    id: this.options.licenseId + '_' + $container.find('input[name="product_id"]').val(),
                    name: $container.find('h1.name').text().trim(),
                    price: $container
                        .find('.price em.main-price')
                        .text()
                        .replace(/([^\d,\.])+/g, ''),
                    brand: $container.find('.manufacturer a').attr('title') || '',
                    category: $container.data('category') || '',
                    variant: this.getStockInfo($container),
                    position: 0
                };

                clientProduct = {
                    id: $container.find('input[name="product_id"]').val(),
                    name: $container.find('h1.name').text().trim(),
                    price: $container
                        .find('.price em.main-price')
                        .text()
                        .replace(/([^\d,\.])+/g, ''),
                    brand: $container.find('.manufacturer a').attr('title') || '',
                    category: $container.data('category') || '',
                    variant: this.getStockInfo($container),
                    position: 0
                };

                customerPrivacy.onPlatformConsentGranted(() => {
                    if (Shop.values.partnerEE) {
                        shopLayer.push({
                            ecommerce: {
                                currencyCode: this.options.currency,
                                detail: {
                                    products: [product]
                                }
                            }
                        });
                    }
                });

                customerPrivacy.onAnalyticsConsentGranted(() => {
                    if (Shop.values.clientEE) {
                        if (window.ga) {
                            ga('client.ec:addProduct', clientProduct);
                            ga('client.ec:setAction', 'detail');
                        }
                    }
                });

                if (
                    Features.isEnabled('partner_google_analytics_v4') ||
                    Features.isEnabled('google_analytics_v4') ||
                    Features.isEnabled('facebook_business_extension')
                ) {
                    const eventName = PRODUCT_DISPLAYED;
                    const eventOptions = new AnalyticProduct({ ...clientProduct, currency: this.options.currency });

                    $emit(eventName, eventOptions);
                }
            }
        },

        productClicked: function ($container, $li) {
            var product;
            var clientProduct;
            var list;

            if ($container.length > 0) {
                list = $container.parents('.box').attr('id') ? $container.parents('.box').attr('id').split('_')[1] : 'undefined';

                product = {
                    id: this.options.licenseId + '_' + $container.data('productId'),
                    name: $container.find('.productname').text().trim(),
                    price: $container
                        .find('.price em:first')
                        .text()
                        .replace(/([^\d,\.])+/g, ''),
                    brand: $container.data('producer') || '',
                    category: $container.data('category') || '',
                    variant: this.getStockInfo($container),
                    position: $container.index()
                };

                clientProduct = {
                    id: $container.data('productId'),
                    name: $container.find('.productname').text().trim(),
                    price: $container
                        .find('.price em:first')
                        .text()
                        .replace(/([^\d,\.])+/g, ''),
                    brand: $container.data('producer') || '',
                    category: $container.data('category') || '',
                    variant: this.getStockInfo($container),
                    position: $container.index()
                };
            }

            if ($li.length > 0) {
                list = $li.parents('.box').attr('id') ? $li.parents('.box').attr('id').split('_')[1] : 'undefined';

                product = {
                    id: this.options.licenseId + '_' + $li.data('productId'),
                    name: $li.find('a').text().trim(),
                    price: '',
                    brand: '',
                    category: '',
                    variant: '',
                    position: $li.index()
                };

                clientProduct = {
                    id: $li.data('productId'),
                    name: $li.find('a').text().trim(),
                    price: '',
                    brand: '',
                    category: '',
                    variant: '',
                    position: $li.index()
                };
            }

            if ($container.length > 0 || $li.length > 0) {
                list = this.getMainlistName(list);

                customerPrivacy.onPlatformConsentGranted(() => {
                    if (Shop.values.partnerEE) {
                        shopLayer.push({
                            event: 'productClick',
                            ecommerce: {
                                currencyCode: this.options.currency,
                                click: {
                                    actionField: {
                                        list: list
                                    },
                                    products: [product]
                                }
                            }
                        });
                    }
                });

                customerPrivacy.onAnalyticsConsentGranted(() => {
                    if (Shop.values.clientEE) {
                        if (window.ga) {
                            ga('client.ec:addProduct', clientProduct);
                            ga('client.ec:setAction', 'click', { list: list });
                            ga('client.send', 'event', list, 'click', '');
                        }
                    }
                });

                if (
                    Features.isEnabled('partner_google_analytics_v4') ||
                    Features.isEnabled('google_analytics_v4') ||
                    Features.isEnabled('facebook_business_extension')
                ) {
                    const eventName = PRODUCT_CLICKED;
                    const eventOptions = new AnalyticProduct({
                        ...clientProduct,
                        listName: list,
                        currency: this.options.currency
                    });

                    $emit(eventName, eventOptions);
                }
            }
        },

        addedToCart: function ($form) {
            var product;
            var clientProduct;
            var list;
            var $container;

            $container = $form.parents('.product');
            if ($container.length > 0) {
                list = $container.parents('.box').attr('id') ? $container.parents('.box').attr('id').split('_')[1] : 'undefined';
                list = this.getMainlistName(list);

                product = {
                    id: this.options.licenseId + '_' + $container.data('productId'),
                    name: $container.find('.productname').text().trim(),
                    price: $container
                        .find('.price em:first')
                        .text()
                        .replace(/([^\d,\.])+/g, ''),
                    brand: $container.data('producer') || '',
                    category: $container.data('category') || '',
                    variant: this.getStockInfo($container),
                    quantity: $container.find('input[name="quantity"]').val() || 1,
                    position: $container.index()
                };

                clientProduct = {
                    id: $container.data('productId'),
                    name: $container.find('.productname').text().trim(),
                    price: $container
                        .find('.price em:first')
                        .text()
                        .replace(/([^\d,\.])+/g, ''),
                    brand: $container.data('producer') || '',
                    category: $container.data('category') || '',
                    variant: this.getStockInfo($container),
                    quantity: $container.find('input[name="quantity"]').val() || 1,
                    position: $container.index()
                };
            } else {
                list = 'detailview';
                $container = $form.parents('#box_productfull');

                if ($container.length > 0) {
                    product = {
                        id: this.options.licenseId + '_' + $container.find('input[name="product_id"]').val(),
                        name: $container.find('h1.name').text().trim(),
                        price: $container
                            .find('.price em.main-price')
                            .text()
                            .replace(/([^\d,\.])+/g, ''),
                        brand: $container.find('.manufacturer a').attr('title') || '',
                        category: $container.data('category') || '',
                        variant: this.getStockInfo($container),
                        quantity: $container.find('input[name="quantity"]').val() || 1,
                        position: 0
                    };

                    clientProduct = {
                        id: $container.find('input[name="product_id"]').val(),
                        name: $container.find('h1.name').text().trim(),
                        price: $container
                            .find('.price em.main-price')
                            .text()
                            .replace(/([^\d,\.])+/g, ''),
                        brand: $container.find('.manufacturer a').attr('title') || '',
                        category: $container.data('category') || '',
                        variant: this.getStockInfo($container),
                        quantity: $container.find('input[name="quantity"]').val() || 1,
                        position: 0
                    };
                }
            }

            if ($form.find('input[name="stock_id"]').length > 0 && $form.find('input[name="required"]').length <= 0) {
                customerPrivacy.onPlatformConsentGranted(() => {
                    if (Shop.values.partnerEE) {
                        shopLayer.push({
                            event: 'addToCart',
                            ecommerce: {
                                currencyCode: this.options.currency,
                                add: {
                                    products: [product],
                                    actionField: {
                                        list: list
                                    }
                                }
                            }
                        });
                    }
                });

                customerPrivacy.onAnalyticsConsentGranted(() => {
                    if (Shop.values.clientEE && window.ga) {
                        ga('client.ec:addProduct', clientProduct);
                        ga('client.ec:setAction', 'add', { list: list });
                        ga('client.send', 'event', 'UX', 'click', 'add to cart');
                    }
                });

                if (
                    Features.isEnabled('partner_google_analytics_v4') ||
                    Features.isEnabled('google_analytics_v4') ||
                    Features.isEnabled('facebook_business_extension')
                ) {
                    const eventName = PRODUCT_ADDED_TO_CART;
                    const eventOptions = new AnalyticProduct({
                        ...clientProduct,
                        currency: this.options.currency,
                        listName: list
                    });

                    $emit(eventName, eventOptions);
                }
            }
        },

        removedFromCart: function ($container) {
            var product;
            var clientProduct;

            if ($container.length > 0) {
                product = {
                    id: this.options.licenseId + '_' + $container.data('productId'),
                    name:
                        $container.find('.product-name').length > 0
                            ? $container.find('.product-name').text().trim()
                            : $container.find('.name a').text().trim(),
                    price:
                        $container.find('.product-price').length > 0
                            ? $container
                                  .find('.product-price')
                                  .text()
                                  .replace(/([^\d,\.])+/g, '')
                            : $container
                                  .find('.price')
                                  .text()
                                  .replace(/([^\d,\.])+/g, ''),
                    brand: $container.data('producer') || '',
                    category: $container.data('category') || '',
                    variant:
                        $container.find('.product-variant').length > 0
                            ? $container.find('.product-variant').text()
                            : $container.find('.variant').text(),
                    quantity:
                        $container.find('.product-amount').length > 0
                            ? $container.find('.product-amount').text()
                            : $container.find('.quantity input').val(),
                    position: $container.index()
                };

                clientProduct = {
                    id: $container.data('productId'),
                    name:
                        $container.find('.product-name').length > 0
                            ? $container.find('.product-name').text().trim()
                            : $container.find('.name a').text().trim(),
                    price:
                        $container.find('.product-price').length > 0
                            ? $container
                                  .find('.product-price')
                                  .text()
                                  .replace(/([^\d,\.])+/g, '')
                            : $container
                                  .find('.price')
                                  .text()
                                  .replace(/([^\d,\.])+/g, ''),
                    brand: $container.data('producer') || '',
                    category: $container.data('category') || '',
                    variant:
                        $container.find('.product-variant').length > 0
                            ? $container.find('.product-variant').text()
                            : $container.find('.variant').text(),
                    quantity:
                        $container.find('.product-amount').length > 0
                            ? $container.find('.product-amount').text()
                            : $container.find('.quantity input').val(),
                    position: $container.index()
                };

                customerPrivacy.onPlatformConsentGranted(() => {
                    if (Shop.values.partnerEE) {
                        shopLayer.push({
                            event: 'removeFromCart',
                            ecommerce: {
                                currencyCode: this.options.currency,
                                remove: {
                                    products: [product]
                                }
                            }
                        });
                    }
                });

                customerPrivacy.onAnalyticsConsentGranted(() => {
                    if (Shop.values.clientEE && window.ga) {
                        ga('client.ec:addProduct', clientProduct);
                        ga('client.ec:setAction', 'remove');
                        ga('client.send', 'event', 'UX', 'click', 'remove form cart');
                    }
                });

                if (
                    Features.isEnabled('partner_google_analytics_v4') ||
                    Features.isEnabled('google_analytics_v4') ||
                    Features.isEnabled('facebook_business_extension')
                ) {
                    const eventName = PRODUCT_REMOVED_FROM_CART;
                    const eventOptions = new AnalyticProduct({
                        ...clientProduct,
                        currency: this.options.currency
                    });

                    $emit(eventName, eventOptions);
                }
            }
        },

        getStockInfo: function ($container) {
            var stockInfo;

            stockInfo = '';
            if ($container.length > 0 && $container.find('.stocks').length > 0) {
                $container.find('.stocks > .f-row').each(function () {
                    var label;
                    var input;
                    var value;

                    label = $(this).find('div.label label').text().replace('* ', '');
                    input = $(this).find('div.stock-options input, div.stock-options select');

                    switch (input.attr('type')) {
                        case 'file': {
                            value = input[0].files[0] ? input[0].files[0].name : '';
                            break;
                        }
                        case 'text': {
                            value = input.val();
                            break;
                        }
                        case 'radio': {
                            value = input.filter(':checked').parent().next().text();
                            break;
                        }
                        case 'checkbox': {
                            if (input.is(':checked')) {
                                value = input.next().attr('data-yes');
                            }
                            break;
                        }
                        case undefined: {
                            value = input.find(':selected').text();
                            break;
                        }
                    }

                    if (value && value !== Shop.lang.common.product_stock_select) {
                        stockInfo += label + value + '; ';
                    }
                });

                return stockInfo
                    .trim()
                    .replace(/(\r\n|\n|\r)/gm, '')
                    .replace(/\s+/g, ' ');
            } else {
                return '';
            }
        },

        getMainlistName: function (listName) {
            var className;

            if (listName === 'mainproducts') {
                className = $('body').attr('class') || '';

                if (className.indexOf('shop_index') >= 0) {
                    return 'homepage';
                }

                if (className.indexOf('shop_product_producer') >= 0) {
                    return 'vendor';
                }

                if (className.indexOf('shop_product_new') >= 0) {
                    return 'new products';
                }

                if (className.indexOf('shop_product_promo') >= 0) {
                    return 'on sale';
                }

                if (className.indexOf('shop_product_list') >= 0 && $('body').attr('id').indexOf('shop_category0') >= 0) {
                    return 'search';
                }

                if (className.indexOf('shop_product_list') >= 0) {
                    return 'category';
                }

                return 'homepage';
            }

            return listName;
        }
    });
})();
