export const GOOGLE_ANALATYICS_V4_GTM_CLIENT = 'GoogleAnalyticsV4GTMClient';
export const GOOGLE_ANALATYICS_V4_GTM_PLATFORM = 'GoogleAnalyticsV4GTMPlatform';
export const GOOGLE_ANALATYICS_V4_GTAG = 'GoogleAnalyticsV4Gtag';
export const GOOGLE_ANALATYICS_V4_GTAG_CAMPAIGN = 'GoogleAnalyticsV4GtagCampaign';
export const FACEBOOK_BUSINESS_EXTENSION = 'FacebookBusinessExtension';

export const SERVICES_NAMES_LIST = [
    GOOGLE_ANALATYICS_V4_GTM_CLIENT,
    GOOGLE_ANALATYICS_V4_GTM_PLATFORM,
    GOOGLE_ANALATYICS_V4_GTAG,
    GOOGLE_ANALATYICS_V4_GTAG_CAMPAIGN,
    FACEBOOK_BUSINESS_EXTENSION
] as const;
